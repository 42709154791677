import { RequestLayer, Interceptors } from '@jotforminc/request-layer';

const layer = new RequestLayer('/', {
  interceptorConfig: {
    teamID: global.teamID,
    customResponseInterceptors: [Interceptors.requestManagerResponseNormalizer],
    cacheTTL: 0
  }
});

const checkEveryMs = 15 * 60 * 1000; // 15 minutes
const fetchUser = () => layer.get('/API/user/combinedinfo');

let firstUser = null;

const mainFN = () => {
  if (window.JOTFORM_ENV !== 'DEVELOPMENT') {
    window.csaTimeout = setInterval(() => {
      fetchUser().then(res => {
        if (!firstUser) {
          firstUser = res.credentials.username;
          return;
        }
        const requestUsername = res.credentials.username;
        if (firstUser !== requestUsername) window.location.reload();
      }).catch(e => console.error(e));
    }, checkEveryMs);
  }
};

mainFN();

export default mainFN;
